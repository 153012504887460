<template>
  <div id="terminal_screenshot_component">
    <div class="terminal_screenshot_container">
      <div class="terminal_screenshot_container__item title_container">
        <div class="title_container__item title_status_container">
          <div class="title_status_container__item title">
            <label>Скриншоты</label>
          </div>
          <div v-if="showLoad" class="title_status_container__item">
            <div class="spring-spinner">
              <div class="spring-spinner-part top">
                <div class="spring-spinner-rotator"></div>
              </div>
              <div class="spring-spinner-part bottom">
                <div class="spring-spinner-rotator"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="title_container__item create_button">
          <button
            v-if="online && !anyWaitingTask"
            @click="onCreateScreen"
            class="blue_button"
          >
            Создать
          </button>
          <button v-if="!online || anyWaitingTask" class="gray_button">
            Создать
          </button>
        </div>
      </div>
      
      <div class="terminal_screenshot_container__item task_status_container">
        <action-bar
          :show="actionBarData.main.show"
          @close-action-bar="resetActionBar('main')"
          :status="actionBarData.main.status"
          :action="actionBarData.main.action"
          :broadDescription="actionBarData.main.broadDescription"
        />
      </div>
      <div v-if="screens.length ===0" class="terminal_screenshot_container__item not_found">Не найдено</div>
      <div v-else class="terminal_screenshot_container__item screens_container">
        <div
          v-for="(el, i) in screens"
          :key="i"
          class="screen_container__item screen_shot_container"
        >
          <div class="screen_shot_container__item screen_title_container" :class="showNewScreen && i === 0 ? 'selected' : ''">
            <div class="screen_title_container__item date">
              <label>{{ el.date }}</label>
            </div>
            <div class="screen_title_container__item delete_button">
              <button title="Удалить" @click="onDelete(el.taskId)">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.83301 15.5C2.37467 15.5 1.98245 15.3369 1.65634 15.0108C1.32967 14.6842 1.16634 14.2917 1.16634 13.8333V3H0.333008V1.33333H4.49967V0.5H9.49967V1.33333H13.6663V3H12.833V13.8333C12.833 14.2917 12.67 14.6842 12.3438 15.0108C12.0172 15.3369 11.6247 15.5 11.1663 15.5H2.83301ZM11.1663 3H2.83301V13.8333H11.1663V3ZM4.49967 12.1667H6.16634V4.66667H4.49967V12.1667ZM7.83301 12.1667H9.49967V4.66667H7.83301V12.1667Z"
                    fill="#010101"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            v-if="el.taskState === 'OK'"
            class="screen_shot_container__item screen_img"
          >
            <a v-if="el.pictureAvaliable" :href="el.image" target="_blank"
              ><img
                :src="el.image"
                @error="() => (el.pictureAvaliable = false)"
                width="244"
                height="145"
            /></a>
            <svg
              width="80px"
              height="80px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.828 5l-1-1H22v15.172l-1-1v-.69l-3.116-3.117-.395.296-.714-.714.854-.64a.503.503 0 0 1 .657.046L21 16.067V5zM3 20v-.519l2.947-2.947a1.506 1.506 0 0 0 .677.163 1.403 1.403 0 0 0 .997-.415l2.916-2.916-.706-.707-2.916 2.916a.474.474 0 0 1-.678-.048.503.503 0 0 0-.704.007L3 18.067V5.828l-1-1V21h16.172l-1-1zM17 8.5A1.5 1.5 0 1 1 15.5 7 1.5 1.5 0 0 1 17 8.5zm-1 0a.5.5 0 1 0-.5.5.5.5 0 0 0 .5-.5zm5.646 13.854l.707-.707-20-20-.707.707z"
              />
              <path fill="none" d="M0 0h24v24H0z" />
            </svg>
          </div>
          <div
            v-if="el.taskState === 'WAITING'"
            class="screen_shot_container__item screen_img screen_img__waiting"
          >
            <label>WAITING</label>
          </div>
          <div
            v-if="el.taskState === 'ERROR'"
            class="screen_shot_container__item screen_img screen_img__error"
          >
            <label>ERROR</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
  },
  props: {
    terminalId: {
      type: Number,
    },
    online: {
      type: Boolean,
    },
  },
  data() {
    return {
      showLoad: false,
      showNewScreen: false,
      actionBarData: {
        main: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
      screens: [],
    };
  },
  methods: {
    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },
    
    // delete screen
    onDelete(taskId) {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Удаление screenshot",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/task?taskId=${taskId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (delete screen data)", res);
          if (res.status === 200) {
            this.screens = this.screens.filter(
              (e) => e.taskId !== Number(taskId)
            );
            this.actionBarData.main.status = "OK";
            setTimeout(() => {
              this.resetActionBar("main");
            }, 4000);
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to delete screen", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Удаление screenshot"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },
    // create new screenshot
    onCreateScreen() {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Запрос screenshot",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/screenshots?terminalId=${this.terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          console.log(
            "Response from proxy api obtained (create screenshots data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.screens.unshift({
                  date: taskResponse.data.payload.createDttm,
                  image: taskResponse.data.payload.taskData.result,
                  taskId: taskResponse.data.payload.taskId,
                  taskState: taskResponse.data.payload.taskState,
                  pictureAvaliable: true,
                });
                this.showNewScreen = true;
                this.actionBarData.main.status = "OK";
                setTimeout(() => {
                  this.resetActionBar("main");
                }, 4000);
                setTimeout(() => {
                  this.showNewScreen = false
                }, 4000);
                
                return;
              }
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = taskResponse.data.payload;

            })
            .catch((error) => {
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = error.data;
            });
        })
        .catch((error) => {
          console.log("Error to get screen", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Запрос screenshot"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },
    // gets all screens
    getScreens() {
      this.showLoad = true;
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/tasks?terminalId=${this.terminalId}&taskType=screenshots`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log(
            "Response from proxy api obtained (screenshots data)",
            res
          );
          const data = [];
          res.data.payload.tasks.forEach((el) => {
            data.push({
              date: el.createDttm,
              image: el.taskData.result,
              taskId: el.taskId,
              taskState: el.taskState,
              pictureAvaliable: true,
            });
          });
          this.screens = data.sort((a, b) => {return Date.parse(b.date, "yyyy-MM-dd HH:mm:ss") - Date.parse(a.date, "yyyy-MM-dd HH:mm:ss")})
        })
        .catch((error) => {
          console.log("Error to get screenshots data", error);
        })
        .finally(() => {
          this.showLoad = false;
        });
    },
  },

  computed: {
    anyWaitingTask() {
      return this.actionBarData.main.status === "WAITING"
    },
  },
  mounted() {
    this.getScreens();
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";

.terminal_screenshot_container {
  display: flex;
  flex-direction: column;
  padding: 25px 25px;

  .not_found {
    display: flex;
    justify-content: center;
  }
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.screens_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
  overflow-y: scroll;
  max-height: 70vh;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.screen_shot_container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  button {
    all: unset;
  }
  margin-left: 5px;
  margin-bottom: 10px;
  width: 244px;


  .screen_shot_container__item {
    &.screen_img {
      width: 244px;
      height: 145px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.screen_img__waiting {
        background-color: #f0ac4eca;
      }
      &.screen_img__error {
        background-color: #f44c4b;
      }
      &.screen_img__inprocess {
        background-color: #4150b7;
      }
    }
  }
}

.title_status_container {
  display: flex;
  align-items: center;

  .title {
    margin-right: 15px;
  }
}

.task_status_container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  height: 40px;
  justify-content: center;
}


.screen_title_container {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  align-items: center;

  &.selected {
    background-color: #f1f6ff;
  }
  .delete_button {
    button {
      all: unset;
      padding: 5px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
  }
}

</style>
